import React, { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useParams } from "react-router-dom";
import styles from './Product.module.scss';


const Product = () => {
  const firestore = useFirestore();
  const { id } = useParams();

  const [product, setProduct] = useState(null);

  const getProductDetails = async () => {
    try {
      const docRef = firestore.collection("products").doc(id);
      const result = await docRef.get();
      if (result.exists) {
        setProduct(result.data());
      } else {
        alert("somthing went wrong");
      }
    } catch (e) {
      console.log("error", e.message);
    }
  };

  useEffect(() => {
    getProductDetails();
  }, []);

  if (!product) {
    return <h2>loading...</h2>;
  }

  return (
    <div className={`container ${styles.prodDetailsPage}`}>
      <div className="row">
        <div className="col-md-12">
          <img className={styles.prodImg} alt={product.productName} src={product.productImg} />
          <h2 className={styles.headText}>{product.productName}</h2>
          <p className={styles.context}>Description: {product.description}</p>
        </div>
      </div>
    </div>
  );
};

export default Product;
