import React, {useRef, useState, useEffect } from "react";
import { useFirestore, useFirebase, useFirestoreConnect } from "react-redux-firebase";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import styles from './AddProduct.module.scss';
import uploadImg from '../../assets/images/uploadimage.png'

const AddProduct = () => {

  const category = useSelector((state) => state.firestore.ordered.category)
    useFirestoreConnect([
        {
            collection:'category',
        }
    ])

  let history = useHistory()
  const firestore = useFirestore();
  const firebase = useFirebase();
  const { id } = useParams();
  const uuid = uuidv4();

  var productName = useRef(null);
  var productCategory = useRef(null);
  var description = useRef(null);
  var features = useRef(null);
  var benefits = useRef(null);
  var packaging = useRef(null);
  var storing = useRef(null);
  var notes = useRef(null);

  var uses = useRef(null);
  var technical = useRef(null);
  var applicationtemp = useRef(null);
  var packagingstorage = useRef(null);
  var internationalspecifications = useRef(null);
  var enginepower = useRef(null);
  var startupmode = useRef(null);
  var hydraulicmotorpower = useRef(null);
  var transmission = useRef(null);
  var heating = useRef(null);
  var numbertankcylinders = useRef(null);
  var numbertankcapacity = useRef(null);
  var cylinderstructure = useRef(null);
  var insulation = useRef(null);
  var cylinderbottomplatematerial = useRef(null);
  var cylinderbottomplatethickness = useRef(null);
  var panelmaterial = useRef(null);
  var dieseltankcapicity = useRef(null);
  var heatingtemperature = useRef(null);
  var recommendeddischargetemperature = useRef(null);
  var weight = useRef(null);
  var volume = useRef(null);
  var dimensions = useRef(null);
  var coating = useRef(null);
  var speciality = useRef(null);
  var reflectivity = useRef(null);

  const [image, setImage] = useState('');
  const [imagePath, setImagePath] = useState('');
  console.log(image)


  const getProductById = async () =>{
    if(id){
    try {
        const docRef = firestore.collection("products").doc(id);
        const result = await docRef.get();
        if(result.exists){
          const formData =result.data()
          console.log(formData.productName)
          if(id){
            productName.current.value = formData.productName;
            productCategory.current.value = formData.categoryId;
            description.current.value = formData.description;
            features.current.value = formData.features;
            benefits.current.value = formData.benefits;
            packaging.current.value = formData.packaging;
            storing.current.value = formData.storing;
            notes.current.value = formData.notes;
            uses.current.value = formData.uses;
            technical.current.value = formData.technical;
            applicationtemp.current.value = formData.applicationtemp;
            packagingstorage.current.value = formData.packagingstorage;
            internationalspecifications.current.value = formData.internationalspecifications;
            enginepower.current.value = formData.enginepower;
            startupmode.current.value = formData.startupmode;
            hydraulicmotorpower.current.value = formData.hydraulicmotorpower;
            transmission.current.value = formData.transmission;
            heating.current.value = formData.heating;
            numbertankcylinders.current.value = formData.numbertankcylinders;
            numbertankcapacity.current.value = formData.numbertankcapacity;
            cylinderstructure.current.value = formData.cylinderstructure;
            insulation.current.value = formData.insulation;
            cylinderbottomplatematerial.current.value = formData.cylinderbottomplatematerial;
            cylinderbottomplatethickness.current.value = formData.cylinderbottomplatethickness;
            panelmaterial.current.value = formData.panelmaterial;
            dieseltankcapicity.current.value = formData.dieseltankcapicity;
            heatingtemperature.current.value = formData.heatingtemperature;
            recommendeddischargetemperature.current.value = formData.recommendeddischargetemperature;
            weight.current.value = formData.weight;
            volume.current.value = formData.volume;
            dimensions.current.value = formData.dimensions;
            coating.current.value = formData.coating;
            speciality.current.value = formData.speciality;
            reflectivity.current.value = formData.reflectivity;
            setImagePath(formData.productImg)
          }
          console.log(productName)
        }else{
            alert('somthing went wrong');
        }
    } catch (e) {
        console.log('error', e.message);
    }
  }
} 
  useEffect(() =>{
    getProductById();
  },[]);

  const onSubmitHandle= (e) =>{
    e.preventDefault();
    if(id){
        console.log(id)
        updateData()
    }else{
      const formData ={
        productName: productName.current.value,
        categoryId: productCategory.current.value,
        description: description.current.value,
        features: features.current.value,
        benefits: benefits.current.value,
        packaging: packaging.current.value,
        storing: storing.current.value,
        notes: notes.current.value,
        uses : uses.current.value,
        technical : technical.current.value,
        applicationtemp : applicationtemp.current.value,
        packagingstorage : packagingstorage.current.value,
        internationalspecifications : internationalspecifications.current.value,
        enginepower : enginepower.current.value,
        startupmode : startupmode.current.value,
        hydraulicmotorpower : hydraulicmotorpower.current.value,
        transmission : transmission.current.value,
        heating : heating.current.value,
        numbertankcylinders : numbertankcylinders.current.value,
        numbertankcapacity : numbertankcapacity.current.value,
        cylinderstructure : cylinderstructure.current.value,
        insulation : insulation.current.value,
        cylinderbottomplatematerial : cylinderbottomplatematerial.current.value,
        cylinderbottomplatethickness : cylinderbottomplatethickness.current.value,
        panelmaterial : panelmaterial.current.value,
        dieseltankcapicity : dieseltankcapicity.current.value,
        heatingtemperature : heatingtemperature.current.value,
        recommendeddischargetemperature : recommendeddischargetemperature.current.value,
        weight : weight.current.value,
        volume : volume.current.value,
        dimensions : dimensions.current.value,
        coating : coating.current.value,
        speciality : speciality.current.value,
        reflectivity : reflectivity.current.value,
        productImg: '',
      }
    const storageRef = firebase.storage().ref()
    const imageRef = storageRef.child('products/'+uuid+image.name);
    const uploadTask = imageRef.put(image)
    uploadTask.on("state_changed", console.log, console.error, () => {
    imageRef.getDownloadURL()
    .then((url) => {
      formData.productImg = url;
      firestore.collection("products")
      .add({
          ...formData,
          createdAt: firestore.FieldValue.serverTimestamp()
        })
    });
    
    })
    alert('product added sucessfully')
    history.push('/');
    }
  }

  const updateData =()=>{
    const formData ={
      productName: productName.current.value,
      categoryId: productCategory.current.value,
      description: description.current.value,
      features: features.current.value,
      benefits: benefits.current.value,
      packaging: packaging.current.value,
      storing: storing.current.value,
      notes: notes.current.value,
      uses : uses.current.value,
      technical : technical.current.value,
      applicationtemp : applicationtemp.current.value,
      packagingstorage : packagingstorage.current.value,
      internationalspecifications : internationalspecifications.current.value,
      enginepower : enginepower.current.value,
      startupmode : startupmode.current.value,
      hydraulicmotorpower : hydraulicmotorpower.current.value,
      transmission : transmission.current.value,
      heating : heating.current.value,
      numbertankcylinders : numbertankcylinders.current.value,
      numbertankcapacity : numbertankcapacity.current.value,
      cylinderstructure : cylinderstructure.current.value,
      insulation : insulation.current.value,
      cylinderbottomplatematerial : cylinderbottomplatematerial.current.value,
      cylinderbottomplatethickness : cylinderbottomplatethickness.current.value,
      panelmaterial : panelmaterial.current.value,
      dieseltankcapicity : dieseltankcapicity.current.value,
      heatingtemperature : heatingtemperature.current.value,
      recommendeddischargetemperature : recommendeddischargetemperature.current.value,
      weight : weight.current.value,
      volume : volume.current.value,
      dimensions : dimensions.current.value,
      coating : coating.current.value,
      speciality : speciality.current.value,
      reflectivity : reflectivity.current.value,
      productImg: '',
      createdAt: firestore.FieldValue.serverTimestamp()
    }
  const storageRef = firebase.storage().ref()
  const imageRef = storageRef.child('products/'+uuid+image.name);
  const uploadTask = imageRef.put(image)
  uploadTask.on("state_changed", console.log, console.error, () => {
  imageRef.getDownloadURL()
  .then((url) => {
    formData.productImg = url;
    const updateRef = firestore.collection("products").doc(id);
    updateRef.update({
        ...formData
      })
  });
  
  })
  alert('product updated sucessfully')
    history.push('/');
  }


  const onImageChange = (e) => {
    const reader = new FileReader();
    let file = e.target.files[0];
    if (file) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          console.log(file);
          setImage(file);
          setImagePath(reader.result)
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setImage(null);
    }
  };

  return (
    <div className={`container ${styles.containerBox}`}>
      <form onSubmit={onSubmitHandle}>

    <div className="row">
        <div className="col-md-12">
          <div className={styles.imgUploadBox}
          >
            <input
            type="file"
            required
            accept="image/x-png,image/jpeg"
            onChange={(e) => {onImageChange(e); }}
          />
          <img className={styles.imgUpload} src={uploadImg} alt="img" />
         </div>
         {imagePath &&(
          <img className={styles.preViewImg} src={imagePath} alt="img"/>
         )}
        </div>

        <div className={`col-md-12 ${styles.inputBox}`}>
        <label>Product Name</label>
          <input
            type="text"
            className={`form-control ${styles.inputArea}`}
            ref={productName}
            required
          />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Product Description</label>
          <textarea
            rows="3"
            className={`form-control ${styles.inputArea}`}
            ref={description}
            required
          />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Features</label>
        <textarea
          type="text"
          rows="3"
          className={`form-control ${styles.inputArea}`}
          ref={features}
           />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Benefits</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={benefits}
          />
        </div>

        {/* new fields start */}

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Uses</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={uses}
          />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Technical</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={technical}
          />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Application Temp</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={applicationtemp}
          />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Packaging and Storage</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={packagingstorage}
          />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>International Specifications</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={internationalspecifications}
          />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Engine Power</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={enginepower}
          />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Start up mode</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={startupmode}
          />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Hydraulic motor Power</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={hydraulicmotorpower}
          />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Transmission</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={transmission}
            />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Heating</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={heating}
            />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Number of Tanks/Cylinders</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={numbertankcylinders}
            />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Tank/Cylinder Capacity</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={numbertankcapacity}
            />
        </div>
        
        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Cylinder Structure</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={cylinderstructure}
            />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Insulation</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={insulation}
            />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Cylinder Bottom Plate Material</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={cylinderbottomplatematerial}
            />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Cylinder Bottom Plate Thickness</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={cylinderbottomplatethickness}
            />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Panel Material</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={panelmaterial}
            />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Diesel Tank Capicity</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={dieseltankcapicity}
            />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Heating Temperature</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={heatingtemperature}
            />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Recommended Discharge Temperature</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={recommendeddischargetemperature}
            />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Weight</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={weight}
            />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Volume</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={volume}
            />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Dimensions</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={dimensions}
            />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Coating</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={coating}
            />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Speciality</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={speciality}
            />
        </div>

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Reflectivity</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={reflectivity}
            />
        </div>

        {/* new fields ends */}

        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Packaging</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={packaging}
          />
        </div>
        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Storing</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={storing}
          />
        </div>
        <div className={`col-md-6 ${styles.inputBox}`}>
        <label>Notes</label>
          <textarea
            rows="2"
            className={`form-control ${styles.inputArea}`}
            ref={notes}
          />
        </div>

        <div className={`col-md-12 ${styles.inputBox}`}>
          <div className="form-group">
            <label>Category</label>
            <select ref={productCategory} className="form-control">
              {category && category.map(cat =>(
                <option key={cat.id} value={cat.id} defaultValue={cat.id}>{cat.categoryName}</option>
              ))}
            </select>
          </div>
        </div>

          <div className="offset-md-5 m-t-20 col-md-4">
          <button type="submit" className={`btn btn-primary btn-block ${styles.saveBtn}`}>
          {id ? "update product" : "Add Product"}
        </button>
          </div>

        </div>
      </form>
      </div>
  );
};

export default AddProduct;