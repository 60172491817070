import React from 'react'
import ProductCard from '../../components/organisms/productcard/ProductCard'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom';
import styles from './Products.module.scss';


const Products = () => {

    const {id} = useParams();
    
    useFirestoreConnect([
        {
            collection:'products',
            where: [['categoryId', '==', id]],
        }
    ])

    const products = useSelector((state) => state.firestore.ordered.products)

    return (
        <div className="container">
            <div className="row">
                {products && products.map((product) => (
                    <div className={`col-md-4 col-sm-6 col-6 ${styles.productsBox}`} key={product.id}>
                        <Link to={`/product/${product.id}`}>
                            <ProductCard 
                            name={product.productName}
                            description={product.productDescription}
                            image={product.productImg}
                            alt={product.productName}
                            />
                        </Link>
                    </div>
                ))}
                
            </div>
        </div>
           
    )
}

export default Products
