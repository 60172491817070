import React from "react";
import {Navbar, Nav, Container} from 'react-bootstrap';
import { Link } from "react-router-dom";
import styles from './Header.module.scss';
import { useFirebase } from "react-redux-firebase";

const Header = () => {

  const firebase = useFirebase();

  const logout = async () =>{
  await firebase.logout();
  }

  return (
<Navbar className={styles.headerBox} collapseOnSelect expand="lg" >
  <Container>
    <Link className={styles.brandName} to="/">Cawnpore Construction
</Link>
    <Navbar.Toggle className={styles.toggleBtn} aria-controls="basic-navbar-nav"/>
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className={`ml-auto ${styles.navLink}`}>
    <Link to="/">
        Home
      </Link>
      <Link  to="/add-product">
        Add Products
      </Link>
      <div onClick={logout}>
        Logout
      </div>
      
    </Nav>
    </Navbar.Collapse>
    </Container>
  </Navbar>
  );
};

export default Header;
