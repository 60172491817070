import React from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {Button} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ProductCard from '../../components/organisms/productcard/ProductCard';
import styles from './Home.module.scss';

const Home = () => {

    // useFirestoreConnect([
    //     {
    //         collection:'category',
    //     }
    // ])
    useFirestoreConnect([
        {
            collection:'products',
        }
    ])
    // const category = useSelector((state) => state.firestore.ordered.category)
    const products = useSelector((state) => state.firestore.ordered.products)

    const handleDelete = () =>{
        alert('not working right now, please wait will work on it :)')
    }
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h2 className={styles.headText}>Products</h2>
                </div>
                {/* {category && category.map(cat =>(
                    <div key={cat.id} 
                    className="col-md-4">
                        <Link to={`/products/${cat.id}`}>
                        <h3 style={{backgroundColor:'#3b71bf',
                         padding:'12px',
                         textAlign:'center',
                          color:'#fff'}}
                        >{cat.categoryName}</h3>
                        </Link>
                        </div>
                ))} */}

                {products && products.map((product) => (
                    <div className={`col-md-4 col-sm-6 col-6 ${styles.productBox}`} key={product.id}>
                        <div className={styles.btnBox}>
                        <Link to={`/add-product/${product.id}`}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={styles.button}
                            startIcon={<EditIcon />}>
                                Edit
                        </Button>
                        </Link>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={styles.button}
                            startIcon={<DeleteIcon />}
                            onClick={handleDelete}
                            >
                            Delete
                        </Button>
                        </div>
                        <Link to={`/product/${product.id}`}>
                            <ProductCard 
                            name={product.productName}
                            description={product.productDescription}
                            image={product.productImg}
                            alt={product.productName}
                            />
                        </Link>
                        
                    </div>
                ))}

            </div>
        </div>
    )
}

export default Home
