import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { createStore, combineReducers } from 'redux';
import {firebaseReducer} from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore';
import {composeWithDevTools} from 'redux-devtools-extension'

const fbConfig = {
  apiKey: "AIzaSyCOKSPxnreR3IlVctQh7lXYfiq9YvBY0tQ",
  authDomain: "cawnporeconstruction-e58b6.firebaseapp.com",
  projectId: "cawnporeconstruction-e58b6",
  storageBucket: "cawnporeconstruction-e58b6.appspot.com",
  messagingSenderId: "16688247478",
  appId: "1:16688247478:web:f0c80375ee8be546322c17",
  measurementId: "G-J1PXB3CC55"
}

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true
}

// Initialize firebase instance
firebase.initializeApp(fbConfig)
firebase.firestore()

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer
})

// Create store with reducers and initial state
const initialState = {}
const store = createStore(rootReducer, initialState, composeWithDevTools())

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance
}

export default store;