import React from 'react';
import {Button} from '@material-ui/core';


const Btn = ({btnText, fullWidth, disabled,className}) => {
    return (
            <Button variant="contained"
             color="primary"
             type="submit"
             className={className}
             disabled={disabled}
             fullWidth={fullWidth}>
            {btnText}
            </Button>
    )
}

export default Btn;
