import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Header from './components/organisms/header/Header';
import Home from './containers/home/Home';
import Products from './containers/products/Products';
import Product from './containers/product/Product';
import AddProduct from './containers/add-product/AddProduct';
import Login from './containers/login/Login';
import PrivateRoutes from './PrivateRoutes';

function App() {
  return (
    
     <Router>
       <div className="stickyHeader">
       <PrivateRoutes component={Header} />
     </div>
       <Switch>
         <PrivateRoutes exact path="/" component={Home} />
         <Route exact path="/login" component={Login} />
         <PrivateRoutes exact path="/products/:id" component={Products} />
         <PrivateRoutes exact path="/product/:id" component={Product} />
         <PrivateRoutes path="/add-product/:id?" component={AddProduct} />
       </Switch>
     </Router>
  );
}

export default App;
