import React from 'react';
import Btn from '../../components/atoms/Button/Button';
import { useFirebase } from 'react-redux-firebase';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import styles from './Login.module.scss';

const Login = () => {

    const firebase = useFirebase();
    const router = useHistory();

    const formik = useFormik({
        initialValues: {
          email: '',
          password: '',
        },
        onSubmit: async values => {
          const userData={
            email: values.email.toString().trim(),
            password: values.password
          }
          await  firebase.login(userData)
            router.push('/')
        },
      });

    return (
        
            <div className={styles.loginPage}>
                <div className={styles.loginBox}>
                <h5 className={styles.headingtext}>Cawnpore Construction</h5>
         <form onSubmit={formik.handleSubmit}>
             <label>Username</label>
           <input
             type="email"
             className={`form-control ${styles.inputBox}`}
             name="email"
             onChange={formik.handleChange}
             onBlur={formik.handleBlur}
             value={formik.values.email}
           />
           {formik.errors.email && formik.touched.email && formik.errors.email}
           <label>Password</label>
           <input
             type="password"
             name="password"
             className={`form-control ${styles.inputBox}`}
             onChange={formik.handleChange}
             onBlur={formik.handleBlur}
             value={formik.values.password}
           />
           {formik.errors.password && formik.touched.password && formik.errors.password}
           <Btn type="submit" disabled={formik.isSubmitting}
             btnText={'Login'}
             className={styles.submitBtn}
             fullWidth={true}
             />
             
         </form>
            </div>
            </div>
        
    )
}

export default Login;
