import React from 'react'
import styles from './ProductCard.module.scss';

const ProductCard = ({name, image, alt}) => {
    return (
        <div className={styles.productCard}>
        <img src={image} className={styles.productImg} alt={alt} />
            <h5 className={styles.headingText}>{name}</h5>
        </div>
    )
}

export default ProductCard;
